'use client';

import { useLocalization } from '@akinon/next/hooks';
import { Link } from 'components';

export const HeaderStoresLink = () => {
  const { t } = useLocalization();

  return (
    <Link
      className="uppercase font-scala"
      href="/address/stores"
      passHref={true}
    >
      {t('common.header.stores')}
    </Link>
  );
};
