'use client';

import { ReactNode, useMemo, useRef, useCallback } from 'react';
import { useGetBasketQuery } from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import {
  closeMiniBasket,
  toggleMiniBasket
} from '@akinon/next/redux/reducers/root';
import clsx from 'clsx';
import { ROUTES } from 'routes';

import MiniBasket from './mini-basket';
import { Badge, Icon, Link } from 'components';
import { useOnClickOutside } from '@akinon/next/hooks';

interface MenuItem {
  label: string;
  url?: string;
  action?: () => void;
  icon: string;
  className?: string;
  badge?: ReactNode;
  miniBasket?: ReactNode;
  dataTestId?: string;
}

export default function ActionMenu() {
  const dispatch = useAppDispatch();

  const { data } = useGetBasketQuery();
  const totalQuantity = useMemo(() => data?.total_quantity ?? 0, [data]);

  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const miniBasketRef = useRef(null);
  const closeMiniBasketCb = useCallback(() => {
    if (miniBasketOpen) dispatch(closeMiniBasket());
  }, [miniBasketOpen, dispatch]);
  useOnClickOutside(miniBasketRef, closeMiniBasketCb);

  const MenuItems: MenuItem[] = [
    {
      label: 'Favourite Products',
      url: ROUTES.ACCOUNT_WISHLIST,
      icon: 'heart-stroke',
      dataTestId: 'header-favourite'
    },
    {
      label: 'Basket',
      action() {
        dispatch(toggleMiniBasket());
      },
      icon: 'cart',
      dataTestId: 'header-basket',
      badge: (
        <Badge
          className={clsx(
            'absolute -end-3/4 top-1/2 flex h-6 w-6 items-center justify-center rounded-full',
            totalQuantity === 0
              ? 'bg-primary text-gray-100'
              : 'bg-secondary-500 text-white'
          )}
        >
          {totalQuantity}
        </Badge>
      ),
      miniBasket: <MiniBasket />
    }
  ];

  return (
    <ul className="flex items-center gap-x-4 lg:gap-x-4 xl:gap-x-8">
      {MenuItems.map((menu, index) => (
        <li
          key={index}
          className={clsx('relative flex items-center', menu.className)}
          ref={menu.miniBasket ? miniBasketRef : null}
        >
          {menu.action ? (
            <button onClick={menu.action} data-testid={menu.dataTestId}>
              <Icon name={menu.icon} size={20} />
              {menu.badge}
            </button>
          ) : (
            <Link
              href={menu.url ?? '#'}
              passHref={true}
              onClick={(event) => {
                if (menu.action) {
                  event.preventDefault();
                  menu.action();
                }
              }}
              data-testid={menu.dataTestId}
            >
              <Icon name={menu.icon} size={20} />
              {menu.badge}
            </Link>
          )}
          {menu.miniBasket}
        </li>
      ))}
    </ul>
  );
}
