'use client';

import clsx from 'clsx';
import { Icon } from './icon';
import { useEffect, useState } from 'react';
import { useSelectedLayoutSegment } from 'next/navigation';

const BackToTop = () => {
  const [isHidden, setIsHidden] = useState(true);
  const [bottom, setBottom] = useState(null);
  const segment = useSelectedLayoutSegment();

  useEffect(() => {
    if (segment === 'category' || segment === 'product') {
      setBottom('bottom-[4.5rem]');
    } else {
      setBottom('bottom-2.5');
    }
  }, [segment]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const heightToHideFrom = 600;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  };

  return (
    <div
      className={clsx(
        'fixed w-10 h-10 bg-secondary rounded-md left-4 rtl:right-4 rtl:left-0 sm:left-10 sm:rtl:right-10 lg:left-7 lg:rtl:right-7',
        'flex items-center justify-center cursor-pointer z-[9] transition-all duration-300',
        `${isHidden ? 'opacity-0 invisible' : 'opacity-100 visible'}`,
        `${bottom} lg:bottom-5`
      )}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <Icon name="chevron-up" size={18} className="text-white" />
    </div>
  );
};

export default BackToTop;
