'use client';

import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  basketApi,
  useGetBasketQuery,
  useUpdateQuantityMutation
} from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { BasketItem } from '@akinon/next/types';

import { Price, Button, Icon, LoaderSpinner, Image, Link } from 'components';
import { ROUTES } from 'routes';
import {
  useCommonProductAttributes,
  useLocalization
} from '@akinon/next/hooks';
import { pushRemoveFromCart } from '@theme/utils/gtm';
import { twMerge } from 'tailwind-merge';

interface MiniBasketItemProps {
  basketItem: BasketItem;
  highlightedItem: number;
  miniBasketListRef: MutableRefObject<HTMLUListElement>;
}

function MiniBasketItem(props: MiniBasketItemProps) {
  const { basketItem, highlightedItem, miniBasketListRef } = props;
  const dispatch = useAppDispatch();
  const [updateQuantityMutation] = useUpdateQuantityMutation();
  const commonProductAttributes = useCommonProductAttributes({
    attributes: basketItem.product.attributes_kwargs
  });
  const { t } = useLocalization();

  const isHighlighted = useMemo(() => {
    return highlightedItem === basketItem.product.pk;
  }, [highlightedItem, basketItem.product.pk]);

  useEffect(() => {
    const miniBasketList = miniBasketListRef.current;

    if (highlightedItem === basketItem.product.pk) {
      if (miniBasketList.scrollTop > 0) {
        miniBasketList.scrollTop = 0;
      }
    }
  }, [highlightedItem, basketItem.product.pk]);

  const removeItem = () => {
    updateQuantityMutation({
      product: basketItem.product.pk,
      quantity: 0,
      attributes: {} // TODO: Handle attributes
    })
      .unwrap()
      .then((data) =>
        dispatch(
          basketApi.util.updateQueryData(
            'getBasket',
            undefined,
            (draftBasket) => {
              Object.assign(draftBasket, data.basket);
            }
          )
        )
      );
    pushRemoveFromCart(basketItem);
  };

  return (
    <li
      style={{ order: isHighlighted ? '-1' : '0' }}
      className={clsx(
        'flex gap-3 py-4 border-b border-gray-500 last:border-b-0'
      )}
    >
      <Link
        href={basketItem.product.absolute_url}
        className={clsx(
          'block shrink-0 transition-all duration-300',
          isHighlighted ? 'w-24 h-40' : 'w-14 h-20'
        )}
      >
        <Image
          src={basketItem.product.productimage_set?.[0]?.image ?? ''}
          alt={basketItem.product.name}
          width={isHighlighted ? 96 : 56}
          height={isHighlighted ? 160 : 80}
          className="transition-all duration-300"
        />
      </Link>
      <div className="uppercase">
        <div className="flex flex-row justify-between items-start">
          <Link
            href={basketItem.product.absolute_url}
            className="block text-[12px] leading-[20px]"
          >
            {basketItem.product.name}
            {/* TODO: Get correct variants */}
            {commonProductAttributes.map((attribute, index) => (
              <span
                key={index}
                data-testid={`mini-basket-item-${attribute.name.toLowerCase()}`}
                className="block"
              >
                {attribute.name}: {attribute.value}
              </span>
            ))}
          </Link>
          <Icon
            name="trash"
            size={14}
            className="transition-all duration-300 hover:cursor-pointer hover:text-black pl-4 text-gray-400"
            onClick={removeItem}
          ></Icon>
        </div>
        <div className="w-5 border-b border-black py-2"></div>
        <footer className="flex flex-col justify-normal mt-3 text-[#333333]">
          <div className="mr-6 text-[11px]">
            {t('basket.card.qty')} : {basketItem.quantity}{' '}
          </div>
          <div className={twMerge(
            'flex flex-col',
            Number(basketItem.retail_price) > Number(basketItem.unit_price) ? 'mt-2' : 'mt-5',
          )}>
            {
              Number(basketItem.retail_price) > Number(basketItem.unit_price) && (
                <Price
                  value={basketItem.retail_price}
                  className={twMerge('text-gray-650 font-normal line-through font-sans uppercase leading-4 rtl:mr-0.5 rtl:text-right')}
                />
              )
            }
            <Price
              value={basketItem.unit_price}
              className={twMerge(
                "text-sm",
                Number(basketItem.retail_price) > Number(basketItem.unit_price) && 'text-secondary'
              )}
            />
          </div>
        </footer>
      </div>
    </li>
  );
}

export default function MiniBasket() {
  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const dispatch = useAppDispatch();
  const { data: basket, isLoading, isSuccess } = useGetBasketQuery();
  const { t } = useLocalization();
  const { highlightedItem } = useAppSelector((state) => state.root.miniBasket);
  const [highlightedItemPk, setHighlightedItemPk] = useState(0);
  const [sortedBasket, setSortedBasket] = useState([]);

  const totalQuantity = useMemo(() => basket?.total_quantity ?? 0, [basket]);
  const miniBasketList = useRef();

  useEffect(() => {
    if (highlightedItem > 0) {
      setHighlightedItemPk(highlightedItem);
    }
  }, [highlightedItem]);

  useEffect(() => {
    if (isSuccess) {
      if (highlightedItemPk > 0) {
        setSortedBasket(
          basket.basketitem_set.slice().sort((a, b) => {
            if (a.product.pk === highlightedItemPk) {
              return -1;
            } else if (b.product.pk === highlightedItemPk) {
              return 1;
            } else {
              return a.product.pk - b.product.pk;
            }
          })
        );
      } else {
        setSortedBasket(basket.basketitem_set);
      }
    }
  }, [isSuccess, highlightedItem, basket]);

  return (
    <>
      <div
        className={clsx(
          miniBasketOpen
            ? 'opacity-100 visible lg:invisible'
            : 'opacity-0 invisible',
          'fixed top-0 left-0 z-50 w-screen h-screen bg-black bg-opacity-80 transition-all duration-300'
        )}
        onClick={() => {
          dispatch(closeMiniBasket());
        }}
      />
      <div
        className={clsx(
          miniBasketOpen
            ? 'flex flex-col opacity-100 visible lg:translate-y-[calc(100%)]'
            : 'opacity-0 invisible translate-x-full lg:translate-x-0 lg:translate-y-[calc(100%+16px)]',
          'fixed lg:absolute bottom-0 lg:-bottom-1 right-0 h-screen lg:h-auto bg-white lg:border-l lg:border-t lg:border-r-2 lg:border-b-2 lg:border-gray-500 z-50 transition-all duration-300 font-sans p-5 w-full sm:w-96',
          'rtl:left-0 rtl:right-[unset]'
        )}
      >
        <header className="flex items-center gap-2 pb-2 border-b uppercase text-[#333333]">
          <h3 className="text-[12px]">{t('basket.mini_basket.my_bag')}</h3>
          <Icon
            name="close"
            size={8}
            className="ml-auto fill-primary hover:cursor-pointer bg-primary text-primary-foreground p-[8px] rounded-full"
            onClick={() => dispatch(closeMiniBasket())}
          />
        </header>
        {isLoading && <LoaderSpinner />} {/* TODO: Fix spinner position */}
        {isSuccess && (
          <ul
            ref={miniBasketList}
            className="overflow-y-auto lg:max-h-64 flex flex-col px-5"
          >
            {sortedBasket.map((basketItem) => (
              <MiniBasketItem
                key={basketItem.product.pk}
                basketItem={basketItem}
                highlightedItem={highlightedItem}
                miniBasketListRef={miniBasketList}
              />
            ))}
          </ul>
        )}
        <footer className="flex flex-col gap-3 mt-auto lg:mt-3 lg:flex-1">
          <div className="flex flex-row justify-between gap-1 px-3 pt-2 uppercase font-sans text-[#333333]">
            <span className="text-[11px]">
              {t('basket.mini_basket.subtotal')}
            </span>
            <span className="text-[16px]">
              <Price value={basket?.total_amount} />
            </span>
          </div>
          {/* TODO: Fix link styles */}
          <Link
            onClick={() => {
              dispatch(closeMiniBasket());
            }}
            href={ROUTES.BASKET}
            data-testid="mini-basket-view-bag"
            className="w-full bg-primary text-primary-foreground border border-primary tansition-all hover:bg-primary-foreground hover:text-primary text-[14px] leading-[20px] text-center py-3"
          >
            {t('basket.mini_basket.view_bag')}
          </Link>
        </footer>
      </div>
    </>
  );
}
