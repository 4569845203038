'use client';

import { useAppDispatch } from '@akinon/next/redux/hooks';
import { openSearch } from '@akinon/next/redux/reducers/header';
import { Icon } from 'components';

export default function DesktopSearch() {
  const dispatch = useAppDispatch();

  return (
    <button
      onClick={() => dispatch(openSearch())}
      className="flex cursor-pointer items-center gap-2 p-2 text-sm uppercase transition hover:text-secondary"
      data-testid="header-nav-search"
      aria-label="Search Button"
    >
      <Icon name="search" size={18} />{' '}
    </button>
  );
}
